.StudyHall {
  opacity: 1;
  transition: opacity 300ms ease-out;
  height: 100%;
}

.StudyHall.opening {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.StudyHall h1,
.StudyHall h2,
.StudyHall h3 {
  font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
}

.StudyGroupDrawer h1 {
  height: 60px;
  color: #888;
  position: absolute;
  width: 100%;
  font-size: 2em;
  line-height: 60px;
}

.StudyGroupDrawer h1 .close {
  font-family: "Courier New", Courier, monospace;
  position: absolute;
  right: calc(40px + 1.5rem);
  font-weight: 800;
  color: #888;
  cursor: pointer;
}

.StudyGroupDrawer h1 .close:hover {
  color: #aaa;
}

.closed.StudyGroupDrawer h1 {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.open.StudyGroupDrawer h1 {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.StudyHall .StudyHallContents {
  height: calc(100% - 60px);
  color: black;
  display: relative;
}

.StudyHall .StudyGroupHeader {
  background-color: #888;
  height: 4em;
  margin-bottom: 2em;
  padding: 0.5em;
  border-radius: 1rem;
  margin-top: 60px;
  padding-left: 1em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -18px;
  padding-left: 2em;
  display: flex;
  justify-content: space-between;
}

.StudyGroupHeader .Share:hover {
  background-color: #666;
}

.StudyGroupHeader .Share {
  background-color: #999;
  margin-right: 0em;
  border-radius: 1rem;
  width: 6em;
  line-height: 3em;
  color: #fff;
}

.StudyGroupHeader .Share span {
  font-size: 2em;
  float: left;
  margin-left: 0.3em;
}

.StudyGroupHeader div.GroupName {
  color: white;
  text-align: left;
  font-weight: 800;
  font-size: 2em;
}

.StudyGroupHeader .btn.invite
{
  padding: 1ex;
  margin-top: .5ex;
  font-size: .7rem;
  line-height: .5rem;
  height: 2.2rem;
  margin-right: 1em;
}


.btn.invite img
{
    height: 1rem;
    filter: invert();
}


.StudyHall .StudyGroupBody {
  height: calc(100% - 4em - 2em);
  display: flex;
  position: relative;
}

.StudyHall .StudyGroupBody > div {
  text-align: left;
  border-radius: 1rem;
  border-top-left-radius: 0;
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar {
  width: 15em;
  background-color: #ccc;
  overflow-y: auto;
  border-top-right-radius: 0em;
  border-bottom-right-radius: 0em;
  border-right: 3px solid #aaa;
  transition: width 1s;
}

.StudyHall .StudyGroupBody > div.StudyGroupChatPanel {
  width: calc(100% - 15em);
  margin-left: 0em;
  background-color: #eee;
  border-top-left-radius: 0em;
  border-bottom-left-radius: 0em;
  transition: width 1s;
}




.StudyHall .StudyGroupBody .StudyGroupChatPanel .PrevMessageLoader,
.StudyHall .StudyGroupBody .StudyGroupChatPanel .dmTitle{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  font-size: large;
  height: 2.55em;
  line-height: 2.55em;
  text-align: center;
  background-color: #999E;
  color: #FFF;
  z-index: 99;
  font-weight: 800;
}


.StudyHall .StudyGroupBody .StudyGroupChatPanel .nexttothread .PrevMessageLoader,
.StudyHall .StudyGroupBody .StudyGroupChatPanel .nexttothread .dmTitle{
  width: 55%;
}

.dmTitle img,
.PrevMessageLoader img
{
  height: 1em;
  filter: invert();
  opacity: .3;
}




.StudyHall .StudyGroupBody > div.StudyGroupSideBar .label {
  font-weight: 800;
  text-align: left;
  line-height: 3em;
  transition: opacity 1s ease-in;
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar li.active {
  background-color: #666;
  color: white;
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo {
  white-space: nowrap;
  opacity: 1;
  transition: opacity 1s ease-in;
}


.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo .userLink a
{
  font-size: xx-small;
  color: #777;
}
.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo .userLink .fromNow
{
  margin-left: 1ex;
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo .userLink img{
  height: 1em;
  opacity: 0.5;
  margin-right: 1ex;
}
.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo .userLink .link
{
  margin-left: 1.5em;
}


.StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo .statRow
{
  display: flex;
}


@media only screen and (max-width: 1500px) {
  .StudyHall .StudyGroupBody > div.StudyGroupSideBar {
    width: 5.5em;
  }
  .StudyHall .StudyGroupBody > div.StudyGroupSideBar .userInfo,
  .StudyHall .StudyGroupBody > div.StudyGroupSideBar .label {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  .StudyHall .StudyGroupBody > div.StudyGroupChatPanel {
    width: calc(100% - 5em);
  }
}

.StudyHall .StudyGroupSideBar ul {
  padding-left: 0;
  list-style: none;
  background-color: #eee;
}

.StudyHall .StudyGroupSideBar .divider
{
  display: none;
}

.StudyHall .StudyGroupSideBar li {
  border-bottom: 1px solid #aaa;
  padding: 0.5em;
  font-size: 1.2em;
  cursor: pointer;
  display: flex;
  height: 4em;
  overflow: hidden;
  justify-content: left;
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar .icon {
  position: relative;
  height: 2.5em;
  width: 2.5em;
  margin-top: 0em;
  margin-left: .5em;
  margin-right: 1em;
  opacity: 0.75;
  flex-shrink: 0;
}


.StudyHall .StudyGroupBody > div.StudyGroupSideBar .discussion .icon {
  position: absolute;
  margin-left: 0.5ex;
  margin-top: 0pt;
  opacity: 0.2;
  filter: invert();
}

.StudyHall .StudyGroupBody > div.StudyGroupSideBar li.active .icon {
  filter: invert();
}
.StudyHall .StudyGroupBody > div.StudyGroupSideBar li .requestCount {
  background-color: red;
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
  color: white;
  border-radius: 50%;
  position: absolute;
  padding: 0;
  font-weight: bold;
  text-align: center;
  line-height: 1.2rem;
  font-size: 0.9rem;
}

.StudyHall .StudyGroupSideBar li:first-child {
  border-top-left-radius: 0;
}

.StudyHall .StudyGroupSideBar li:last-child {
  border-bottom: 0;
}

.StudyHall .StudyGroupSideBar li:hover {
  background-color: #ddd;
}

.StudyHall .StudyGroupSideBar .userCircle {
  margin-right: 1em;
  min-width: 3em;
}

.StudyHall .StudyGroupSideBar .nickname {
  font-weight: bold;
  margin-left: -0.6em;
}

.StudyHall .StudyGroupSideBar .lastSeen {
  font-weight: 400;
  color: #aaa;
  font-size: 0.7em;
  display: inline-block;
  position: relative;
  bottom: 0.2em;
  margin-left: 1em;
}

.StudyHall .StudyGroupSideBar .recentContent {
  color: #666;
  font-style: italic;
  font-size: 0.8em;
}

.StudyGroupSideBar .SideBarToolTip {
  font-weight: 800;
  font-size: 1.2em;
}

.sweet-alert-modal {
  position: absolute !important;
  width: 50em !important;
  top: 15vh;
  left: calc(50vw - 25em + 125px);
  display: none;
}

.sweet-alert-modal code {
  color: #999;
  padding: 1em;
  display: inline-block;
  border-radius: 1rem;
  margin-bottom: 1em;
  cursor: pointer;
}

.sweet-alert-modal code:hover {
  color: #222;
  background-color: #eee;
}

.sweet-alert-modal em {
  color: #000;
  font-weight: 800;
}

.sweet-alert-modal .text-highlight code,
.sweet-alert-modal code:active {
  background-color: rgb(107, 208, 152, 0.5);
  color: black;
}

.coverUrl {
  text-align: center;
  margin-top: 2em;
}

.coverUrl img {
  height: 5em;
  width: 5em;
}

.linkContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.copy-modal {
  position: absolute;
  width: 15em;
  top: 0em;
  left: calc(50% - 7.5em);
  padding: 0.25em 1em;
  color: #666;
  background: #ddd;
  border-radius: 1rem;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 0.2em 0.2em 1em 0 rgb(0, 0, 0, 0.3);
  opacity: 0;
  pointer-events: none;
}

.text-highlight .copy-modal {
  opacity: 1;
  top: -2em;
  transition: opacity 300ms ease-out, top 300ms ease-out;
}

.messageContainer {
  display: flex;
  justify-content: left;
  margin-bottom: 1em;
  position: relative;
}

.messageContent {
  width: 100%;
}

.messageContainer img {
  width: 3em;
  height: 3em;
  margin: 0 0.5em;
  border: 1px solid #777;
  flex-shrink: 0;
}

.messageContainer .senderName {
  font-weight: 800;
  margin-right: 1ex;
}

.messageContainer .senderName .botBadge
{
  background-color: #d3e1ea;
  color: #0006;
  padding: 0 1ex;
  border-radius: 0.5ex;
  font-weight: 400;
  font-size: xx-small;
  border: 1px solid #0006;
  position: relative;
  bottom: 2px;
}

.messageContainer .timeStamp a,
.messageContainer .edit,
.messageContainer .delete,
.messageContainer .likes {
  font-weight: 400;
  color: #bbb;
}

.messageContainer:hover .timeStamp a,
.messageContainer:hover .edit,
.messageContainer:hover .delete,
.messageContainer:hover .likes {
  font-weight: 400;
  color: #666;
}

.messageContainer .edit:hover,
.messageContainer .delete:hover {
  color: black;
}

.messageContainer.self .senderName {
  color: blue;
  background-color: #666;
  color: white;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 0.25em;
}

.messageContainer.same {
  margin-top: -0.8em;
}

.messageContainer.same .same_img,
.messageContainer.same .senderName,
.messageContainer.same .edit,
.messageContainer.same .delete,
.messageContainer.same .timeStamp {
  display: none;
}

.messageContainer.same .messageContent {
  /* margin-left: 4em; */
}

.messageContainer .messageResponses {
  padding-left: 0;
  padding-left: 1em;
  font-weight: 800;
  color: #666;
  padding-left: 1em;
  border-radius: 1rem;
  display: inline-block;
}

.messageContainer .messageResponses img {
  height: 2em;
  width: 2em;
  margin: 0.25em;
}

.messageContainer .messageResponses:hover {
  color: black;
  background-color: #ddd;
}

.thread .notch {
  display: none;
}

.threadCommentInput {
  position: relative;
}

.threadAvatar {
  height: 3em;
  position: absolute;
  top: -0.25em;
  left: 0.5em;
}

.StudyHall .StudyGroupChatPanel {
  width: 100%;
  margin-left: 500ms;
  background-color: #eee;
  display: flex;
}

.StudyHall .StudyGroupChatPanel .unreadMsg
{
  border-top: .3ex dashed #CCC;
  padding-top: 1em;
  margin-top: 2em;
}

.StudyHall .StudyGroupChatPanel .unreadMsg:empty
{
  border:none;
  margin:0;
  padding: 0;
}

.StudyHall .StudyGroupChatPanel .withoutthread {

  width: 100%;
}

.StudyHall .StudyGroupChatPanel .nexttothread {

  width: 55%;
}

.StudyHall .StudyGroupChatPanel .thread {
  width: 45%;
  border-left: 1px solid #aaa;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 2em;
  position: relative;
}

.StudyHall .StudyGroupChatPanel .thread h3.threadHeader {
  background-color: #aaa;
  padding: 0.5em;
  color: #fff;
  font-weight: 800;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
  position: -webkit-sticky;
  /* 사파리 브라우저 지원 */
  position: sticky;
  top: 0;
  z-index: 99;
}

.StudyHall .StudyGroupChatPanel .thread h3 span.close {
  float: right;
  opacity: 0.5;
}

.StudyHall .StudyGroupChatPanel .thread h3 span.close:hover {
  opacity: 1;
}

.edit .StudyGroupChatInput {
  border-top: none;
  background-color: transparent;
  height: auto;
}

.withoutthread .StudyGroupChatInput {
  border-bottom-right-radius: 1rem;
}

.StudyGroupChatInput.sending {
  background-color: #aaa;
  color: #888;
}

.StudyGroupChatInput .topRow {
  display: flex;
  justify-items: stretch;
  padding: 1em;
  padding-bottom: 0;
}

.edit .StudyGroupChatInput .topRow {
  height: 2.5em;
  padding: 0;
}

#inputGroupChat
{
  border:2px solid #CCC;
  border-right: 0;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1ex;
  width: 100%;
  height: 2.15rem;
  min-height:  2.15rem;
  padding: .3ex;
  padding-left: 2ex;
  line-height: 1rem;
  resize: none;
  text-align: justify;
  padding-right: 1ex;
  padding-top: 1.1ex;
}


#inputGroupChat:focus
{
  outline-color: #AAA;
  outline:none
}

.topRow .dropdown
{
  height: 3rem;
}


.StudyGroupChat {
  overflow-y: auto;
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column-reverse;
  transition: height 500ms;
}

.StudyGroupChatInput {
  transition: height 500ms;
  border-top: 1px solid #999;
  background-color: #ddd;
  display: flex;
  flex-flow: column;
}




.StudyGroupChatInput .topRow input {
  width: 100%;
  padding-left: 0.5em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.edit .StudyGroupChatInput input {
  border: 1px solid gray;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.StudyGroupChatInput .topRow button.btn-primary ,
button.advancedSend {
  width: 4em;
  margin: 0;
  line-height: 0;
   border-radius: 1em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #666;
  padding: 0;
}

.StudyGroupChatInput .topRow button.btn-primary img, button.advancedSend img{
  height: 2rem;
  filter:invert()

}
.advancedButtons
{
  display: flex;
  justify-content: space-between;
}

button.advancedSend,
button.advancedExit
{
  float: right;
  border-radius: 1ex;
  height: 2rem;
  margin-right: 1ex;
  margin-top: .3rem;
  padding-left: 1em;
  padding-right: 1ex;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  width: auto;
}

button.advancedSend img
{
  margin-left: 1ex;
  height: 1.5rem;
  opacity: 0.6;
}

button.advancedExit
{
  text-align: center;
  padding:1ex;
  margin-left: 1ex;
}

.StudyGroupChatInput  .bottomRow 
{
  text-align:right;
}

.edit .StudyGroupChatInput button {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}


.StudyGroupChatInput .bottomRow {
  padding-left: 1em;
}

.StudyGroupChatInput .bottomRow div {
  font-weight: bold;
}

.maxi .StudyGroupChatInput {
  height: calc(80%);
  transition: height 500ms;
}

.maxi .StudyGroupChat {
  height: 20%;
  transition: height 500ms;
}

.ql-editor {
  max-height: calc(80vh - 21em)  !important;
}

.StudyGroupChatInput input:focus-visible {
  outline: none;
}
.mini .div-editor {
  display: none;
  border:0;
  outline: 0;
}
.maxi .div-editor {
  flex: 1;
  cursor: text;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow
{
  border: 0;
}
span.desc {
  font-style: italic;
  color: #999;
}

.StudyHall .ql-container,.quill
{
  height: calc(100% - 3em);
  border:0;
  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
}

.StudyHall .ql-toolbar.ql-snow {

  font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
}

.StudyHall .likeCount,
.StudyHall .replyBubble {
  background-color: #eee;
  color: #666;
  border-radius: 1rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: bold;
  border: 1px solid #aaa;
  font-weight: 400;
  font-size: 0.9em;
  opacity: 0.5;
  white-space: nowrap;
  margin-right: 0.25em;
  opacity: 0.2;
}

.StudyHall .likeCount:hover,
.StudyHall .replyBubble:hover {
  background-color: #666;
  color: #fff;
  opacity: 1;
}

.



.StudyHall .messageBody
{
  line-height: 1.2rem;
}
.StudyHall .messageBody:hover .likeCount:not(.hasCount),
.StudyHall .messageBody:hover .replyBubble {
  opacity: 0.5;
}

.StudyHall .messageBody > p:last-of-type

{
  margin-bottom: 0;
}

.StudyHall .messageBody.typing img{
  border:0;
  margin-bottom: -30%;
  margin-top: -30%;
}

.maxi .topRow
{
  display: none;
}

.send-btn-group .btn{
  margin: 0;
  padding: 1ex;
  background-color: #999;
}

.send-btn-group .btn.carret{
  border-left:1px solid #FFF5
}

.send-btn-group img
{
  width: 4em;
  height: 1.8em;
  filter: invert();
  margin: 0ex;
}


.StudyHall .ql-editor p,
.StudyHall .ql-editor ul,
.StudyHall .ql-editor ol
{
  margin-bottom: 1ex;
}

.StudyHall  .ql-editor ul > li::before
{
  content:'\25A0'  ;
}

.StudyHall .ql-editor .ql-indent-1:not(.ql-direction-rtl)
{
  padding-left: 1ex;
  border-left: 3px solid #CCC;
  margin-left: 1em;
}


.StudyHall .likeCount.hasCount {
  opacity: 1;
  font-weight: 800;
  border-width: 2px;
  color: #fff;
  background-color: #666;
}

.StudyHall .likeCount.self {
  background-color: #000;
  border-color: #444;
}

.StudyHall .thread textarea {
  padding: 0.5em;
  line-height: 1.2em;
  text-align: left;
  color: #666;
  margin: 0.3em;
  margin-left: 4em;
  width: calc(100% - 5.6em);
  border-radius: 0.5em;
}

.ck-content {
  height: 20vh;
}


.Message a{
  font-weight: bold;;
  color:#006A;
  word-break: break-all;
}
.Message a:hover{
    border-bottom: 1px solid #0063;
}
