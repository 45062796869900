.tagList {
  list-style: none;
  position: fixed;
  background: white;
  height: 102px;
  overflow: auto;
  padding: 2px 5px;
  margin: 0;
  border-radius: 10px;
  z-index: 1000;
}

.tagListItem {
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
}

.tagListItem:hover {
  background: rgb(196, 196, 196);
}
