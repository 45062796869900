
.narration .images.faxbox{
  margin-left: 0;
}

.faxbox .thumb_tabs {
  float: none;
  position: initial;
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #CCC;
  padding-bottom: 1ex;
  margin-bottom: 1ex;
}
.faxbox .thumb_tabs ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0.3ex;
  margin: 0;
}
.faxbox .thumb_tabs ul li{
  opacity: 0.5;
  filter: saturate(0);
  width: calc(20% - 0.6ex);
  margin: 0;
  padding: 0;
  border-radius: 0.5ex;
  margin-bottom: 1ex;
}
.faxbox .thumb_tabs ul li.active{
  opacity: 1;
  filter: saturate(1);
}
.faxbox .thumb_tabs ul li:hover{
  opacity: 1;
  filter: saturate(1);
}

.faxbox .thumb_tabs ul li img {
  margin: 0;
  height: 100%;
  width: auto;
  object-fit: cover;
}
.faxbox .thumb_tabs ul li.active img {
  outline: 2px solid black;
}

.thumb_tabs li img {
  width: 24px;
  height: 24px;
  margin: 3px;
  object-fit: cover;
  object-position: center;
  /* Center the image within the element */
}

.thumb_tabs li.active {
  opacity: 1;
}

.narration .images .star {
  float: right;
  opacity: 0.25;
  font-weight: normal;
  font-size: 1em;
  cursor: pointer;
}

.narration .images .close {
  float: right;
  position: relative;
  top: -5px;
  cursor: pointer;
  font-size: 2em;
  font-family: "Courier New", monospace;
  margin-top: 0.25em;
}

.narration .images .close:hover {
  color: black;
}

.narration .images .imgWCredit {
  position: relative;
}

.narration .fullscreen-image {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 3%;
  top: 2%;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.4s ease;
}

.narration .fullscreen-image:hover {
  opacity: 1;
  transform: scale(1.1);
}

.narration .images .credit {
  background-color: rgb(0, 0, 0, 0.3);
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: white;
  font-size: small;
  border-top-left-radius: 1ex;

  position: absolute;
  bottom: 0;
  right: 0;

  opacity: 0.5;
}
.narration .images .credit:hover {
  opacity: 1;
}

.narration .images img.panel {
  border: 1px solid #aaa;
  outline: none;
}

.narration .images .star:hover {
  opacity: 0.75;
}

.narration .images {
  margin-left: 2.5em;
  background-color: #ddd;
  padding: 0.5em;
  border-radius: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  position: relative;
}

.narration .images button {
  background-color: #323b4d;
  height: 1em;
  line-height: 0em;
  width: 13em;
  border: 3px solid #aaa;
  opacity: 0.3;
  text-align: left;
  margin: 0;

  position: absolute;
  top: calc(-3em);
}
.narration .images button:hover {
  opacity: 0.7;
}
.narration .images.faxbox button {
  width: 14.5em;
}

.narration .images button:hover {
  opacity: 1;
  border-color: rgb(214, 191, 127);
  color: rgb(233, 219, 182) !important;
}

.narration .images .arrow {
  float: right;
  position: relative;
  right: -2em;
}

.narration .images .heading {
  color: #555;
  font-weight: bold;
  text-align: center;
  line-height: 1em;
  /* margin-top: */
  padding-left: 0.5em;
  padding-bottom: 0.2em;
  line-height: 1.2em;
}

.narration .images .study {
  margin-top: 1ex;
  margin-right: 0.5em;
  position: relative;
}

.narration .images img.panel {
  border: 2px solid black;
  width: 100%;
  background-color: #999;
  min-height: 10vh;
}

.narration .images img.panel.highlighted {
  outline: 5px solid rgb(255, 255, 0, 0.5);
}

.thumb_tabs {
  float: left;
  position: absolute;
  width: 30px;
  margin-top: 2em;
  left: -30px;
}

.thumb_tabs ul {
  list-style-type: none;
  padding: 0;
}

.thumb_tabs li {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  background-color: #ddd;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
  position: relative;
}

.thumb_tabs li span.comment {
  position: absolute;
  bottom: -0.5em;
  left: -0.3em;
}




.narration .images.faxbox .panel {
  min-height: 600px;
  background-size: contain; /* <------ */
  background-repeat: no-repeat;
  background-color: #000;
  cursor: crosshair;
}

.narration .images.faxbox .panel:hover {
  background-size: auto;
}

.narration > div.capsulation_narration
{
  margin-left: 1em;
  list-style-position: outside;
  padding-left: 2em;
  text-align: left;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 1.5em;
  background-image: url("../User/svg/empty.svg");
}

.narration > div.narration_item {
  margin-left: 1em;
  list-style-position: outside;
  padding-left: 2em;
  text-align: left;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 1.5em;
  background-image: url("../User/svg/empty.svg");
}

.narration > div.not_started {
  background-image: url("../User/svg/blank.svg");
}
.narration > div.started {
  background-image: url("../User/svg/yellow.svg");
}
.narration > div.completed {
  background-image: url("../User/svg/green.svg");
}
.narration > div.active {
  background-image: url("../User/svg/blue.svg");
}

.narration .images {
  display: flex;
  flex-direction: column;
}

.narration > div a {
  font-weight: bold;
}


.narration {
  position: relative;
}


.peoplePlacePanelWrapper,
.notesPanelWrapper,
.scripturePanelWrapper
{
  background-color: #ddd;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin-right: 1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;

}
.peoplePlacePanelWrapper h5,
.notesPanelWrapper h5,
.scripturePanelWrapper h5
{

  color: #555;
  font-weight: bold;
  text-align: center;
  line-height: 1em;

  font-size: 14px;
  padding: 0.5em;
  line-height: 1.2em;
  margin-bottom: 0;

}

.peoplePlacePanelWrapper h5 span,
.notesPanelWrapper h5 span,
.scripturePanelWrapper h5 span
{
  float: right;
  cursor: pointer;
  font-weight: bold;
}
.peoplePlacePanelWrapper h5 span:hover,
.notesPanelWrapper h5 span:hover,
.scripturePanelWrapper h5 span:hover
{
  color: #fff;
}


.peoplePlacePanelWrapper h5 span,
.notesPanelWrapper h5 span,
.scripturePanelWrapper h5 span {
  float: right;
  position: relative;
  top: -5px;
  cursor: pointer;
  font-size: 2em;
  font-family: "Courier New", monospace;
  margin-top: 0.25em;
}

.peoplePlacePanelWrapper h5 span:hover,
.notesPanelWrapper h5 span:hover,
.scripturePanelWrapper h5 span:hover {
  color: black;
}

.notesPanel
{
  display: flex;
  flex-direction: column;
}

.peoplePlacePanel,
.scripturePanel{
  padding: 1ex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #EEE;

}

.notesPanel{
  border: 1px solid #CCC;
}

.peoplePlacePanel .item
{
  max-width: 33%;
  cursor: pointer;
}
.peoplePlacePanel .item:hover
{
  filter: brightness(1.2);
  position: relative;
  bottom: 2px;;
}

.peoplePlacePanel .name
{
  
  border-top-right-radius: 1ex;
  border-top-left-radius: 1ex;  
  font-weight: bold;
  line-height: 100%;
}
.peoplePlacePanel .info
{
  font-size: 1.5ex;
  border-bottom-right-radius: 1ex;
  border-bottom-left-radius: 1ex;; 
  line-height: 1.7ex;
  padding: .5ex;
  opacity: 0.6;
  margin-bottom: 1ex;
}
.peoplePlacePanel .name,
.peoplePlacePanel .info
{
    text-align: center;
    background-color: #EEE;
}

.noteItem
{
  display: flex;
  background-color: #EEE;
  position: relative;
  padding: 1ex 1ex 0 1ex;
}
.noteItem:last-child
{
  padding-bottom: 1ex;
}

.noteItem .noteSource
{
  flex-shrink: 0;
}
.noteItem .noteSource img
{
  width: 2rem;
  height: 3rem;
}
.noteItem .noteText{
  display: flex;
  align-items: center;
  line-height: 100%;
  margin-left: 1ex;
} 

.noteItem .noteText em.focusQuote{
  color: #999;
}


/* .image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 12000;
  overflow: hidden;
}

.images {
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  z-index: 12001;
  width: 100%;
  height: 100%;
} 
.fax {
  position: absolute;
  left: -1.65em;
  top: 0em;
  width: 1.8em;
  height: calc(1.5rem + 0px);
  border-radius: 1em;
  border-top-right-radius: 0em;
  border-bottom-right-radius: 0em;
  background-color: #999;
  color: white;
  text-align: center;
  line-height: 1.6rem;
  cursor: pointer;
  opacity: 0;
  padding-left: 8px;
  transition: opacity 500ms;
  border-bottom: 2px solid #999;
}
*/
.fax .comment {
  position: absolute;
  top: 0.9em;
  left: 0.5em;
}

.fax.visible {
  opacity: 1;
  transition: opacity 500ms;
}
.fax:hover {
  opacity: 1;
}

.fax.visible img {
  filter: invert();
  position: absolute;
  top: 0.5ex;
  left: 1ex;
  height: 1.2em;
  width: 1.3em;
}

/* .image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    z-index: 12000;
    overflow: hidden;
  }
  
  .images {
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    z-index: 12001;
    width: 100%;
    height: 100%;
  } */
