/** @format */

.loadBar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.loadBar img {
  width: 100%;
}
