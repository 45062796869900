.StudyHall .StudyGroupChatPanel.progress
{
    border:1px solid black;
    height: 100%;
}

.StudyHall .StudyGroupChatPanel.progress .details
{
    width: calc(100% - 700px);
    border:1px solid black;
}
image.highcharts-point {
    outline: 4px double #AAA;
    width: 3rem;
    height: 3rem;
    position: relative;
    left: -3em;
    transform: translate(4px,-3.2rem);
}