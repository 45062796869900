.StudyGroupChatPanel.admin {
  display: block;
  overflow: auto;
}

.StudyGroupChat
{
  position: relative;
}
.userAdminBoxes
{
    display: flex;
    flex-wrap:  wrap;
    justify-content: space-around;
    align-content: center;
}

.userAdminBox {
  display: block;
  width: 24%;
}

.userAdminBox .card-body > img {
  width: 100%;
  max-width: 5rem;
  max-height: 5rem;
}
.userAdminBox .card-body
{
  display: flex;
  justify-content: center;
  text-align: center;
}

.userAdminBox h5.title {
  font-size: 1.3em;
  margin: 0;
}
.card.userAdminBox .card-header {
  padding: 1ex;
}
.userAdminBox .completed
{
  color: white;
  background-color: gray;
  border-radius: 1ex;
  font-size: small;
  padding: 3px 1ex;
  margin-left: 1em;
  float: right;
}
.contextmenu__item{
  padding: 0 1ex;
}
 img.menuimg{
  width: 2rem;
  opacity: 0.5;
  margin-right: 1ex;
}

.membershipRequests .userId, 
.userAdminBox .userId {
  text-align: center;
  font-family: Consolas, monospace;
  color: #aaa;
  display: none;;
}

.group_profile {
  display: flex;
}
.groupImage {
  height: 6em;
  width: 6em;
  border-radius: 50%;
}

.groupInfo {
  width: calc(100% - 13em);
  margin-left: 1em;
}
.StudyGroupChatPanel
{
  position: relative;
}

.StudyGroupChatPanel.admin .input-group-text {
  background-color: #ddd;
  width: 2em;
  height: 2em;
  position: relative;
  top: 3px;
  margin-left: 1ex;
  margin-right: 1ex;
}
.StudyGroupChatPanel.admin .input-group-text img {
  height: 1rem;
  position: relative;
  left: -3px;
}

.StudyGroupChatPanel.admin .input-group-prepend {
  padding: 0;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.actions {
  float: right;
  font-weight: 800;
  font-size: 1.5em;
  color: #666;
}
.actions:hover {
  color: #000;
}
.StudyGroupChatPanel.admin h5.title {
  white-space: nowrap;
  overflow: hidden;
}

.StudyGroupChatPanel.admin h5.title > img {
  height: 1.5em;
  width: 1.5em;
  margin-right: 1ex;
  opacity: 0.5;
}

.react-contextmenu-item img {
  height: 1em;
  width: 1em;
  margin-right: 1ex;
}

.userAdminBox .statusline {
  line-height: 2em;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1ex;
}
.userAdminBox .statusline img {
  height: 2em;
  width: 2em;
  margin-right: 1ex;
}

.membershipRequests img
{
  width: 5rem;
  height: 5rem;
}

.membershipRequests{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.membershipRequests .card{
  width: 32%;
}
.membershipRequests .card-footer{
  display: flex;
  justify-content: space-between;
}
.membershipRequests .btn
{
  width: 45%;
}