/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  font-weight: normal;
  font-style: normal;
}
/* all icon font classes list here */
