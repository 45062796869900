
.page  .show,
.page  .collapsing {
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
}

.scripture strong{
    color: #000;
    font-weight: 400;
    background-color: #EEE;
}

.row.chronoRow
{
    align-items: center;

    text-align: left;
    font-family: 'Roboto Condensed', 'Arial Narrow', 'sans-serif';
    font-size: x-small;
    font-style: italic;
    margin-bottom: 1rem;
    flex-direction: row;

}

.row.reference.card-header
{
    display: flex;
}

.row.chronoRow div.chronoText{
    width: auto!important;
    background-color: #e7e5e5;
    border-radius: 1ex;
    border-color: #888;
    color: #888;
    margin-left: 1rem;

}

.card .scripture .content {

    padding: 1em 0 0 1em;
}
.card .scripture .content > p,
.card .scripture .content > blockquote {
    font-family: 'Scripture', monospace;
    font-size: 1.1rem;
    text-align: justify;
    line-height: 1.2rem;
    color: #444;
}

.scripture .highlight {
    margin-top: -1em;
}

.scripture .highlight button {
    background-color: #DDD;
    color: #999;
    float: right;
}


.scripture .content p {
    padding: 0 1ex;
}

.scripture .content blockquote p {

    text-align: left;
}


.card .scripture {
    padding-right: 2em;
}

.card .scripture .card .scripture {
    padding-right: 1em;
}

.page .card .scripture .reference {
    font-weight: bold;
    background-color: #999;
    padding: 0 0 0 .5em;
    overflow: hidden;
    height: 1.6rem;
    font-family: 'Roboto Condensed', 'Arial Narrow', 'sans-serif';
    font-size: .9rem;
    line-height: 1.6rem;
    display: flex;
    padding-right: 1ex;
}

.card .scripture .reference a.refheader
{
    flex-grow: 1;
    text-align: left;
}

.card .scripture .reference .text_item_counter{
    opacity: 0;
    pointer-events: none;
    width: 0;
    transition: ease-in-out 0.3s opacity;
}

.card .scripture .reference.open .text_item_counter{
    opacity: 1;
    pointer-events: all;
    width: auto;
    transition: ease-in-out 0.3s opacity;

}
.card .scripture .reference .item_counter img
{
    height: 0.8rem;
    line-height: 1rem;
    margin-right: .3ex;
    opacity: 0.8;
    margin-bottom: 0.2rem;
}


.card .scripture .reference .text_item_counter .item_counter{
    background-color: #00000022;
    border-radius: 1ex;
    padding: 0 0.5ex;
    margin-left: 1ex;
    cursor: pointer;
    color: #FFF;
}

.card .scripture .reference .text_item_counter .item_counter:hover{
    background-color: #00000033;
}

.card .scripture .reference .triangle
{
    display: inline-block;
    font-weight: 800;
    margin-left: 1ex;
    margin-right: 1ex;
    transition: transform 150ms,top 150ms;
    opacity: 0.5;
    position: relative;
    top: 0px;


    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);


    margin-right: 1ex;

}

.card .scripture .reference .square
{
    position: relative;
    top:0px;
    margin-left: .6ex;
    margin-right: .6ex;
    font-size: 1.5em;
    display: inline-block;
    line-height: 0rem;
}

.card .scripture .reference .open > .triangle
{
    transition: transform 150ms, top 150ms;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(-0deg);
    -o-transform: rotate(-0deg);
    -ms-transform: rotate(-0deg);
    transform: rotate(-0deg);

    top: 3px;
}
.card .scripture .reference > .triangle img{
    margin-right: -5ex;

}

.card .scripture .reference .open > .triangle img{
    margin-top: -1ex;

}

.card .scripture .reference:hover .triangle
{
    opacity: 0.7;
}

.triangle img{
    height: 1em;
    width: 1em;
}

.reference.link
{
    line-height: 1em;
}

.page .card-collapse .card .card-header a[data-toggle="collapse"] {
    font-size: 1.2em;
    color: #FFF;
}

.page .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: white;
    float: left;
    margin-left: .25em;
}

.reference .star {
    float: right;
    margin-right: .25em;
    opacity: 0.25;
    font-weight: normal;
    font-size: 1em;
}

.reference .star:hover {
    opacity: 0.75;
}


.main-panel > .content{
    margin-bottom: 60vh;
}

@media(max-width:900px){
  .main-panel > .content{
    margin-bottom: 0px;
}
}

.contenttext
{
    cursor: text;
}



.art_bubble {
    cursor: pointer;
    float: left;
    position: absolute;
    left: -1.5em;
    background-color: #FFF;
    border: 1px solid #AAA;
    height: 2em;
    width: 2em;
    text-align: center;
    /* line-height: 2em; */
    border-radius: .25em;
    display: inline-block;
    line-height: 1.8em;
    margin-top: -.5em;
    /* font-size: .7em; */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    color: #AAA;
    transition: opacity 1000ms ease-in;
}

.art_bubble.active,.art_bubble.active:hover{
    left: calc(-2em + 2px);
    outline: 5px solid rgb(255, 255, 0,.3);
}

.art_bubble:hover{
    outline: 3px solid rgb(157, 165, 179,.3);
}



.card.card-plain .art_bubble img {
    border-radius: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* Center the image within the element */
}


.highlight.primary
{
    background-color: rgb(255, 255, 0,.3);
    outline: 3px solid rgb(255, 255, 200,.3);
    color:black;
    text-decoration:underline;
    text-decoration-color:rgba(209, 165, 82, 0.5);
}
.highlight.secondary
{
    background-color: rgb(157, 165, 179,.3);
    outline: 3px solid rgb(157, 165, 179,.3);
    color:black;
    text-decoration:underline;
    text-decoration-color:rgb(157, 165, 179,.5);
}
.highlight.commented
{
    background-color: rgba(157, 179, 160, 0.3);
    outline: 3px solid rgb(160, 157, 179, 0.3);
    color:black;
    text-decoration:underline;
    text-decoration-color:rgb(160, 157, 179, 0.5);
}

.annotation {
    cursor: pointer;
    float: right;
    font-family: 'Roboto Condensed', 'Arial Narrow', 'sans-serif';
    position: absolute;
    right: -1em;
    background-color: #FFF;
    border: 1px solid #AAA;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    /* line-height: 2em; */
    border-radius: 1em;
    display: inline-block;
    line-height: 1.3em;
    margin-top: -.5em;
    /* font-size: .7em; */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* background-image: url(https://image.flaticon.com/icons/png/512/2947/2947998.png); */
    /* background-op */
    background-size: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(157, 165, 179);
    color: #FFF;
    text-align: center;
    font-weight: 800;

}


.coverPreviewDiv
{
    display: inline-block;
    position: relative;
}

.coverPreviewDiv .comcom
{
    position: absolute;
    bottom: -.2em;
    left:calc(50% - .5em);
    height: 1em;
    width: 1em;
    line-height: 1em;
}

.annotation .comcom
{
    position: absolute;
    bottom:-.7rem;
    right:-.3rem;
    font-size: .8rem;
    line-height: 1rem;
     -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.imgcom
{
    position: absolute;
    left:-2em;
    margin-top: .7em;
    z-index: 98;
    cursor: pointer;
}

.annotation:not(.fadedIn),
.imgcom:not(.fadedIn),
.imgcom:not(.fadedIn),
.annotation:not(.fadedIn) .comcom,
.art_bubble:not(.fadedIn) {
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.annotation.fadedIn,
.imgcom,
.comcom,
.art_bubble.fadedIn{
    transition: opacity 0.3s ease-in;
    opacity: 1;
}

.annotation:hover{
    background-color: rgb(70, 74, 82);
}

.tooltip_books
{
    background-color: rgb(70, 74, 82);
    padding:.5em;
    padding-left:.8em;
    border-radius: 5px;
    z-index: 9;
    text-align: center;
    line-height: 80px;


    position: absolute;
    margin-top:2em;
    right:50%;
    transform: translate(50%);
    max-width: 100%;
    border: 1px solid #666;

}
.text_item_tooltip
{
    padding: 0;
    margin-left: -1ex;
    margin-right: -1ex;
}

.tooltip_books.count5
{
    min-width: 60%;
}


.tooltip_books .com_heading{
    font-family: "Roboto Condensed", 'Arial Narrow', sans-serif;
    color:#EEE;
    line-height: 1em;
    margin-bottom: .3em;
    font-size: .8em;
}

.tooltip_books.visible
{
    opacity: 1;
    transition: opacity 0.2s ease-in;
}
.tooltip_books.invisible
{
    opacity: 0;
    transition: opacity 0.2s ease-in;
}

.card.card-plain .tooltip_books img
{

    border-radius: 3px;
}

.tooltip_books img{
    outline:1px solid #AAA;
    border:1px solid #000;
    width: 50px;
    height: 75px;
    margin-right: 5px;
    cursor: pointer;
}

span.indent{
    width: 1.2em;
    display: inline-block;
}

/* demo/demo.css **** End */


@media only screen and (max-width: 578px) {


   .pagesection .scripture
    {
        margin-left: 2.5em;
        margin-top: -1em;
    }

}

.scripture
{
    position: relative;
}

.scripture .comments
{
    position: absolute;
    top:-0em;
    right:1em;
    z-index: 1;
    font-size: 1.2em;
    background-color: #999;
    width: 1.5em;
    height: 1.4em;
    text-align: center;
    padding-left: .1em;
    border-radius: 1em;
    cursor: pointer;
    opacity: 1;
    transition: opacity 500ms;

}
.scripture.open .comments
{
    opacity: 0;
    transition: opacity 500ms;

}
