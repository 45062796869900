.imgWrapper {
  position: relative;
  flex: 1 1 60%;
  margin-right: 10%;
}

.groupImgWrapper {
  position: relative;
  text-align: center;
}

.profileImage {
  border-radius: 50%;
}

.overlay {
  background-color: rgba(151, 150, 150, 0.5);
  box-shadow: 0px 0px 9px 9px rgba(151, 150, 150, 0.5);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: none;
  position: absolute;
  color: white;
}

.overlayImage {
  width: 32px;
  height: 32px;
  transition: 0.3 all ease;
  cursor: pointer;
}

.show {
  display: block;
}

.dragField {
  border: 1px solid black;
  border-radius: 25px;
  text-align: center;
  margin: 0 auto 20px;
  padding: 20px;
  cursor: pointer;
}
.dragField img{
  height: 10em;
  opacity: 0.3;
}

.imageUploaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.imageUploaderWrapper {
  background: white;
  max-width: 50%;
  border-radius: 10px;
  margin: 7% auto;
  padding: 10px;
  position: relative;
  text-align: center;
}

.imageWrapperHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageWrapperHeaderButton {
  outline: none;
  background: transparent;
  border: none;
  color: grey;
  margin-bottom: 1rem;
}

.cropButton {
  outline: none;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
}

.saveCrop {
  background: rgb(8, 167, 40);
}

.cancelCrop {
  background: rgb(211, 52, 52);
}

.uploadingSpinner {
  margin: 0 auto;
}

.dragField h5 {
  text-align: center;
}
